"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
exports.ComponentNotFound = function (props) {
    return (React.createElement("div", null,
        "Component ",
        props.blok.component,
        " is not defined. Add it to components.js"));
};
