"use strict";
exports.__esModule = true;
exports.TransformImage = function (source, targetSize, filters) {
    var imageService = "//img2.storyblok.com/";
    var path = source.replace("http:", "").replace("https:", "").replace("//a.storyblok.com", "").replace("//img2.storyblok.com", "");
    var transformUrl = imageService;
    if (targetSize) {
        transformUrl += targetSize;
    }
    if (filters) {
        filters = transformUrl.endsWith("/") ? filters : "/" + filters;
        transformUrl += filters;
    }
    return transformUrl + path;
};
