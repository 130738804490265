"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var Components_1 = require("../../Components");
var grid_1 = require("@rebass/grid");
var react_helmet_1 = require("react-helmet");
exports.Page = function (props) {
    var SEO = props.blok.SEO;
    var seoComponents = [];
    if (SEO) {
        seoComponents.push(React.createElement("title", null, SEO.title || "Fritz Lauterbad | Urban Soul meets Black Forest"));
        seoComponents.push(React.createElement("meta", { name: "description", content: SEO.description || "Das erste Smart Luxury Hotel im Schwarzwald." }));
    }
    else {
        seoComponents.push(React.createElement("title", null, "Fritz Lauterbad | Urban Soul meets Black Forest"));
        seoComponents.push(React.createElement("meta", { name: "description", content: "Das erste Smart Luxury Hotel im Schwarzwald." }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(react_helmet_1.Helmet, null,
            React.createElement("link", { rel: "icon", type: "image/png", href: "favicon.png" }),
            seoComponents),
        React.createElement(grid_1.Flex, { flexWrap: "wrap" }, props.blok.body && props.blok.body.map(function (blok) { return React.createElement(Components_1.Components(blok.component), { key: blok._uid, blok: blok }); }))));
};
