"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var gatsby_1 = require("gatsby");
exports.LinkWrapper = function (props) {
    return (props.internal ? (React.createElement(gatsby_1.Link, { to: "/" + props.link }, props.children)) : React.createElement("a", { target: "_BLANK", href: props.link }, props.children));
};
