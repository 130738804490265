"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var react_1 = require("react");
var styled_components_1 = __importStar(require("styled-components"));
var ImageSlider_1 = require("./ImageSlider");
var Components_1 = require("../../Components");
var lauterbad_common_1 = require("@eimerreis/lauterbad-common");
var SliderWrapper = styled_components_1["default"].div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: ", ";\n    width: 100%;\n    position: relative;\n"], ["\n    height: ", ";\n    width: 100%;\n    position: relative;\n"])), function (props) { return props.height ? props.height + "px" : "100vh"; });
var fadeIn = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    0%\u00A0{\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n"], ["\n    0%\u00A0{\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n"])));
var OverlayWrapper = styled_components_1["default"].div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    opacity: 0;\n    animation: ", " 2s ease-in-out;\n    animation-fill-mode: forwards;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n"], ["\n    opacity: 0;\n    animation: ", " 2s ease-in-out;\n    animation-fill-mode: forwards;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n"])), fadeIn);
exports.FullscreenSlider = function (props) {
    var blok = props.blok;
    var _a = react_1.useState("1920x1080"), transformation = _a[0], setTransformation = _a[1];
    react_1.useEffect(function () {
        var _a = lauterbad_common_1.WindowService.GetWindowDimensions(), height = _a.height, width = _a.width;
        setTransformation(width.toFixed(0) + "x" + height.toFixed(0));
    });
    return (React.createElement(SliderWrapper, { height: props.blok.height },
        React.createElement(OverlayWrapper, null, props.blok.overlay && props.blok.overlay.map(function (overlayBlok) { return React.createElement(Components_1.Components(overlayBlok.component), { key: overlayBlok._uid, blok: overlayBlok }); })),
        React.createElement(ImageSlider_1.ImageSlider, { imageTransformations: { size: transformation, filters: "filters:quality(90)" }, header: props.blok.header, height: props.blok.height, images: blok.images.map(function (x) { return x.filename; }), slideDuration: 5000 })));
};
var templateObject_1, templateObject_2, templateObject_3;
