"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var boxicons_logos_1 = require("styled-icons/boxicons-logos");
var boxicons_regular_1 = require("styled-icons/boxicons-regular");
var evaicons_outline_1 = require("styled-icons/evaicons-outline");
var framer_motion_1 = require("framer-motion");
var iconVariants = {
    open: {
        y: 0,
        opacity: 1
    },
    closed: {
        y: -100,
        opacity: 0
    }
};
var Icons = {
    "Instagram": React.createElement(boxicons_logos_1.Instagram, null),
    "Facebook": React.createElement(boxicons_logos_1.Facebook, null),
    "Mail": React.createElement(evaicons_outline_1.EmailOutline, null),
    "Phone": React.createElement(boxicons_regular_1.Phone, null)
};
exports.SocialMediaIcon = function (_a) {
    var href = _a.href, icon = _a.icon;
    return (React.createElement(framer_motion_1.motion.a, { variants: iconVariants, href: href, target: "_BLANK" }, Icons[icon]));
};
