"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var react_1 = require("react");
var styled_components_1 = __importStar(require("styled-components"));
var NavigationTrigger_1 = require("./NavigationTrigger");
var framer_motion_1 = require("framer-motion");
var NavItem_1 = require("./NavItem");
var SocialLinks_1 = require("./SocialLinks");
var SocialMediaIcon_1 = require("./SocialMediaIcon");
var feather_1 = require("@styled-icons/feather");
var NavigationWrapper = styled_components_1["default"](framer_motion_1.motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 100;\n    left: 0;\n    top: 0;\n    background: rgba(0, 0, 0, 0.95);\n    opacity: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n"], ["\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 100;\n    left: 0;\n    top: 0;\n    background: rgba(0, 0, 0, 0.95);\n    opacity: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n"])));
var Eagle = styled_components_1["default"].img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    width: 80px;\n    top: 1rem;\n    left: 1rem;\n"], ["\n    position: absolute;\n    width: 80px;\n    top: 1rem;\n    left: 1rem;\n"])));
var menuVariants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
        transition: { staggerChildren: 0.03, staggerDirection: -1 }
    }
};
var MenuLinks = styled_components_1["default"](framer_motion_1.motion.ul)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    text-align: center;\n    list-style-type: none;\n    text-transform: uppercase;\n    font-family: Fritz Menu;\n    font-size: 2rem;\n    margin: 0;\n    padding: 0;\n"], ["\n    text-align: center;\n    list-style-type: none;\n    text-transform: uppercase;\n    font-family: Fritz Menu;\n    font-size: 2rem;\n    margin: 0;\n    padding: 0;\n"])));
var SocialSeparatorSlide = function (props) { return styled_components_1.keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    from {\n        width: 0px;\n        opacity: 0;\n    }\n\n    to {\n        width: ", ";\n        opacity: 1;\n    }\n"], ["\n    from {\n        width: 0px;\n        opacity: 0;\n    }\n\n    to {\n        width: ", ";\n        opacity: 1;\n    }\n"])), props.width); };
var SocialSeparator = styled_components_1["default"].div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    border-color: #333 !important;\n    border-top: 2px solid;\n    margin-top: 1em;\n    -webkit-animation: ", " 0.5s ease-in-out;\n    animation: ", " 0.5s ease-in-out;\n    -webkit-animation-delay: 1s;\n    animation-delay: 1s;\n    -webkit-animation-fill-mode: forwards;\n    animation-fill-mode: forwards;\n"], ["\n    border-color: #333 !important;\n    border-top: 2px solid;\n    margin-top: 1em;\n    -webkit-animation: ", " 0.5s ease-in-out;\n    animation: ", " 0.5s ease-in-out;\n    -webkit-animation-delay: 1s;\n    animation-delay: 1s;\n    -webkit-animation-fill-mode: forwards;\n    animation-fill-mode: forwards;\n"])), SocialSeparatorSlide({ width: "415px" }), SocialSeparatorSlide({ width: "415px" }));
exports.StickyHeader = styled_components_1["default"].div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: fixed;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 50px;\n    background: rgba(0, 0, 0, 0.6);\n    z-index: 1;\n    transition: 0.4s ease-in-out;\n    -webkit-transition: 0.4s ease-in-out;\n    z-index: 3;\n\n    ", "\n"], ["\n    position: fixed;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 50px;\n    background: rgba(0, 0, 0, 0.6);\n    z-index: 1;\n    transition: 0.4s ease-in-out;\n    -webkit-transition: 0.4s ease-in-out;\n    z-index: 3;\n\n    ",
    "\n"])), function (props) {
    return props.active
        ? "\n        top: 0px;\n        opacity: 1;\n    "
        : "\n        top: -50px;\n        opacity: 0;\n    ";
});
var fadeIn = styled_components_1.keyframes(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n"], ["\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n"])));
var EagleIcon = styled_components_1["default"].img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    height: 32px;\n    margin-left: 12px;\n    opacity: 0;\n    animation: ", " 2s ease-in-out;\n    animation-fill-mode: forwards;\n"], ["\n    height: 32px;\n    margin-left: 12px;\n    opacity: 0;\n    animation: ", " 2s ease-in-out;\n    animation-fill-mode: forwards;\n"])), fadeIn);
var LanguageLink = styled_components_1["default"](framer_motion_1.motion.a)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    position: fixed;\n    right: 75px;\n    top: 16px;\n    display: flex;\n    flex-direction: row;\n    gap: 3px;\n    color: white;\n    z-index: 1000;\n    padding: 0px 8px;\n    background: rgb(180 167 137 / 90%);\n    font-family: \"Oswald\";\n    font-size: 21pt;\n\n    &:hover {\n        rgb(180 167 137 / 100%)\n    }\n"], ["\n    position: fixed;\n    right: 75px;\n    top: 16px;\n    display: flex;\n    flex-direction: row;\n    gap: 3px;\n    color: white;\n    z-index: 1000;\n    padding: 0px 8px;\n    background: rgb(180 167 137 / 90%);\n    font-family: \"Oswald\";\n    font-size: 21pt;\n\n    &:hover {\n        rgb(180 167 137 / 100%)\n    }\n"])));
var getLanguage = function () {
    if (typeof window === "undefined")
        return "";
    return window.location.pathname.startsWith("/en") ? "en" : "de";
};
var buildLanguageSwitchUrl = function (language) {
    if (language === "en") {
        return window.location.pathname.replace("/en", "");
    }
    if (window.location.pathname.match(/\//).length > 1) {
        return "/en" + window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/"));
    }
    return "/en" + window.location.pathname;
};
exports.Navigation = function (_a) {
    var menuItems = _a.menuItems, logo = _a.logo, socialMediaLinks = _a.socialMediaLinks;
    var _b = react_1.useState(false), isActive = _b[0], setIsActive = _b[1];
    var _c = react_1.useState(false), stickyHeader = _c[0], setStickyHeader = _c[1];
    var onTriggerClick = function () {
        setIsActive(!isActive);
    };
    react_1.useEffect(function () {
        window.onscroll = function () {
            var showStickyHeader = document.body.scrollTop > 100 || document.documentElement.scrollTop > 100 ? true : false;
            setStickyHeader(showStickyHeader);
        };
    });
    var language = getLanguage();
    return (React.createElement(React.Fragment, null,
        React.createElement(exports.StickyHeader, { active: stickyHeader },
            React.createElement("a", { href: "/" },
                React.createElement(EagleIcon, { src: logo }))),
        isActive ? (React.createElement(LanguageLink, { initial: { opacity: 0 }, animate: { opacity: 1 }, onClick: function () { return setIsActive(false); }, href: buildLanguageSwitchUrl(language || "de") },
            React.createElement(feather_1.Globe, { style: { width: "27px" } }),
            language === "en" ? "DE" : "EN")) : null,
        React.createElement(NavigationTrigger_1.NavigationTrigger, __assign({}, { stickyHeader: stickyHeader, isActive: isActive, onClick: onTriggerClick })),
        React.createElement(framer_motion_1.AnimatePresence, null, isActive && (React.createElement(NavigationWrapper, { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } },
            React.createElement("a", { href: "/" },
                React.createElement(Eagle, { src: logo })),
            React.createElement(MenuLinks, { initial: "closed", animate: "open", exit: "closed", variants: menuVariants }, menuItems && menuItems.map(function (x) { return React.createElement(NavItem_1.NavItem, __assign({ onClick: function () { return setIsActive(false); }, key: x.href }, x)); })),
            React.createElement(SocialSeparator, null),
            React.createElement(SocialLinks_1.SocialLinks, { variants: SocialLinks_1.socialLinksVariants, initial: "closed", animate: "open", exit: "closed" },
                React.createElement(SocialMediaIcon_1.SocialMediaIcon, { href: socialMediaLinks.Instagram, icon: "Instagram" }),
                React.createElement(SocialMediaIcon_1.SocialMediaIcon, { href: socialMediaLinks.Facebook, icon: "Facebook" }),
                React.createElement(SocialMediaIcon_1.SocialMediaIcon, { href: socialMediaLinks.Mail, icon: "Mail" }),
                React.createElement(SocialMediaIcon_1.SocialMediaIcon, { href: socialMediaLinks.Phone, icon: "Phone" })))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
