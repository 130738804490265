// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-editor-jsx": () => import("./../src/pages/editor.jsx" /* webpackChunkName: "component---src-pages-editor-jsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-templates-storyblok-entry-tsx": () => import("./../src/templates/storyblok-entry.tsx" /* webpackChunkName: "component---src-templates-storyblok-entry-tsx" */)
}

