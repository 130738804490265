"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var Components_1 = require("../../Components");
var DefaultTheme_1 = require("../../themes/DefaultTheme");
var FooterWrapper = styled_components_1["default"].footer(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: inline;\n    min-height: 300px;\n    background-color: ", ";\n    padding: ", " ", ";\n\n    @media ", "\n    }\n\n\n    \n"], ["\n    display: inline;\n    min-height: 300px;\n    background-color: ", ";\n    padding: ", " ", ";\n\n    @media ",
    "\n    }\n\n\n    \n"])), DefaultTheme_1.DefaultTheme.Colors.Background.Dark, DefaultTheme_1.DefaultTheme.Spacing[3], DefaultTheme_1.DefaultTheme.Spacing[5], function (props) { return props.theme && styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", " {\n            /*  Beware of the hacks below => fixes responsive issues in footer\n                Hopefully the footer will never change :D */\n                div > div:nth-child(4) > div, div > div:nth-child(1) > div {\n                    width: calc(100vw - 55px);\n                }\n        "], ["", " {\n            /*  Beware of the hacks below => fixes responsive issues in footer\n                Hopefully the footer will never change :D */\n                div > div:nth-child(4) > div, div > div:nth-child(1) > div {\n                    width: calc(100vw - 55px);\n                }\n        "])), props.theme.DeviceQueries.MobileM); });
exports.Footer = function (props) {
    if (!props.blok) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(FooterWrapper, null, props.blok.Body && props.blok.Body.map(function (blok) { return React.createElement(Components_1.Components(blok.component), { key: blok._uid, blok: blok }); })));
};
var templateObject_1, templateObject_2;
