"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var grid_1 = require("@rebass/grid");
var styled_components_1 = __importStar(require("styled-components"));
var MetroTileWrapper = styled_components_1["default"](grid_1.Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    overflow: hidden;\n    height: 50vh;\n"], ["\n    position: relative;\n    overflow: hidden;\n    height: 50vh;\n"])));
var Text = styled_components_1["default"].h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-family: ", ";\n    color: white;\n    text-transform: uppercase;\n    font-size: ", ";\n    margin: 0;\n    position: absolute;\n    bottom: 48px;\n    left: 24px;\n\n    @media ", "\n    }\n"], ["\n    font-family: ", ";\n    color: white;\n    text-transform: uppercase;\n    font-size: ", ";\n    margin: 0;\n    position: absolute;\n    bottom: 48px;\n    left: 24px;\n\n    @media ",
    "\n    }\n"])), function (props) { return props.theme.Font.Headings.Family; }, function (props) { return props.theme.Spacing[5]; }, function (props) { return props.theme && styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["", " {\n            line-height: 48px;\n            bottom: ", ";\n        "], ["", " {\n            line-height: 48px;\n            bottom: ", ";\n        "])), props.theme.DeviceQueries.MobileM, props.theme.Spacing[7]); });
var Caption = styled_components_1["default"].span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-family: 'Merriweather', serif;\n    font-size: ", ";\n    color: white;\n    position: absolute;\n    bottom: 24px;\n    left: 24px;\n"], ["\n    font-family: 'Merriweather', serif;\n    font-size: ", ";\n    color: white;\n    position: absolute;\n    bottom: 24px;\n    left: 24px;\n"])), function (props) { return props.theme.Spacing[4]; });
var Column = styled_components_1["default"](grid_1.Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: relative;\n"], ["\n    position: relative;\n"])));
var GridWrapper = styled_components_1["default"](grid_1.Flex)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: relative;\n    height: 100%;\n"], ["\n    position: relative;\n    height: 100%;\n"])));
exports.GridMetroTile = function (props) {
    var blok = props.blok;
    return (React.createElement(MetroTileWrapper, { width: [1, 1 / 2] },
        React.createElement(GridWrapper, { flexWrap: "wrap" },
            React.createElement(Column, { backgroundColor: blok.TopLeftColor.color, width: [1 / 2] },
                React.createElement(Text, null, blok.TopLeftText),
                React.createElement(Caption, null, blok.TopLeftCaption)),
            React.createElement(Column, { backgroundColor: blok.TopRightColor.color, width: [1 / 2] },
                React.createElement(Text, null, blok.TopRightText),
                React.createElement(Caption, null, blok.TopRightCaption)),
            React.createElement(Column, { backgroundColor: blok.BottomLeftColor.color, width: [1 / 2] },
                React.createElement(Text, null, blok.BottomLeftText),
                React.createElement(Caption, null, blok.BottomLeftCaption)),
            React.createElement(Column, { backgroundColor: blok.BottomRightColor.color, width: [1 / 2] },
                React.createElement(Text, null, blok.BottomRightText),
                React.createElement(Caption, null, blok.BottomRightCaption)))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
