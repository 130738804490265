"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var Button_1 = require("./Button");
exports.ButtonBlok = function (props) {
    var blok = props.blok;
    return (React.createElement(Button_1.Button, { href: props.blok.Link.cached_url }, blok.Text));
};
