"use strict";
exports.__esModule = true;
var DeviceSizes = {
    Desktop: "2560px",
    Laptop: "1024px",
    LaptopL: "1440px",
    MobileL: "425px",
    MobileM: "375px",
    MobileS: "320px",
    Tablet: "768px"
};
var DeviceQueries = {
    Desktop: "(max-width: " + DeviceSizes.Desktop + ")",
    DesktopL: "(max-width: " + DeviceSizes.Desktop + ")",
    Laptop: "(max-width: " + DeviceSizes.Laptop + ")",
    LaptopL: "(max-width: " + DeviceSizes.LaptopL + ")",
    MobileL: "(max-width: " + DeviceSizes.MobileL + ")",
    MobileM: "(max-width: " + DeviceSizes.MobileM + ")",
    MobileS: "(max-width: " + DeviceSizes.MobileS + ")",
    Tablet: "(max-width: " + DeviceSizes.Tablet + ")"
};
exports.DefaultTheme = {
    Colors: {
        Background: {
            Primary: "",
            Secondary: "",
            Danger: "",
            Success: "",
            Warning: "",
            Dark: "",
            Light: "",
            Info: "",
            White: "#fff"
        },
        Font: {
            Primary: "",
            Secondary: "",
            Danger: "",
            Success: "",
            Warning: "",
            Dark: "",
            Light: "",
            Info: "",
            White: ""
        }
    },
    Font: {
        Base: {
            Family: "",
            Size: "",
            Weight: ""
        },
        Headings: {
            Family: "'Oswald', sans-serif",
            Size: "2rem",
            Weight: ""
        }
    },
    Spacing: {
        0: "0",
        1: "0.25rem",
        2: "0.5rem",
        3: "1rem",
        3.5: "1.15rem",
        4: "1.5rem",
        5: "3rem",
        6: "4.5rem",
        7: "6rem"
    },
    DeviceSizes: DeviceSizes,
    DeviceQueries: DeviceQueries
};
