"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var TileWrapper_1 = require("./TileWrapper");
exports.GoogleMapsTile = function (props) {
    return (React.createElement(TileWrapper_1.TileWrapper, { noPseudoElement: true, width: [1, 1 / 2] },
        React.createElement("iframe", { title: "map", src: props.blok.MapsUrl, style: { width: '100%', height: '100%', border: 0 } })));
};
