"use strict";
exports.__esModule = true;
var ButtonBlok_1 = require("./components/Button/ButtonBlok");
var Table_1 = require("./components/Data/Table");
var ButtonColumn_1 = require("./components/Button/ButtonColumn");
var TextColumn_1 = require("./components/TextColumn");
var Footer_1 = require("./components/Layout/Footer");
var HeroParagraph_1 = require("./components/HeroParagraph");
var Page_1 = require("./components/Layout/Page");
var ViomaBooking_1 = require("./components/Vioma/ViomaBooking");
var ViomaRoomList_1 = require("./components/Vioma/ViomaRoomList");
var ViomaHtml_1 = require("./components/Vioma/ViomaHtml");
var ComponentNotFound_1 = require("./components/ComponentNotFound");
var ImageGallery_1 = require("./components/ImageGallery");
var Image_1 = require("./components/Image");
var FullscreenSlider_1 = require("./components/Sliders/FullscreenSlider");
var Grid_1 = require("./components/Layout/Grid");
var Column_1 = require("./components/Layout/Column");
var MetroTile_1 = require("./components/MetroTile");
var VideoTile_1 = require("./components/MetroTile/VideoTile");
exports.ComponentList = {
    Button: ButtonBlok_1.ButtonBlok,
    buttonColumn: ButtonColumn_1.ButtonColumn,
    Column: Column_1.Column,
    footer: Footer_1.Footer,
    fullMetroTile: MetroTile_1.FullMetroTile,
    fullscreenSlider: FullscreenSlider_1.FullscreenSlider,
    googleMapsTile: MetroTile_1.GoogleMapsTile,
    Grid: Grid_1.Grid,
    gridMetroTile: MetroTile_1.GridMetroTile,
    heroParagraph: HeroParagraph_1.HeroParagraph,
    Image: Image_1.Image,
    ImageGallery: ImageGallery_1.ImageGallery,
    page: Page_1.Page,
    smallMetroTile: MetroTile_1.SmallMetroTile,
    Table: Table_1.Table,
    textColumn: TextColumn_1.TextColumn,
    textMetroTile: MetroTile_1.TextMetroTile,
    ViomaBooking: ViomaBooking_1.ViomaBooking,
    ViomaRoomList: ViomaRoomList_1.ViomaRoomList,
    ViomaHtmlSnippet: ViomaHtml_1.ViomaHtmlSnippet,
    VideoTile: VideoTile_1.VideoTile
};
exports.Components = function (type) {
    if (typeof (exports.ComponentList[type]) === "undefined") {
        return ComponentNotFound_1.ComponentNotFound;
    }
    return exports.ComponentList[type];
};
