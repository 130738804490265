"use strict";
exports.__esModule = true;
var WindowService = /** @class */ (function () {
    function WindowService() {
    }
    WindowService.GetWindowDimensions = function () {
        return {
            height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
            width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        };
    };
    return WindowService;
}());
exports.WindowService = WindowService;
